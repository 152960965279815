<template>
	<div class="chonghuan-home">
		<MyMap />
		<div class="cover cover-home"></div>
		<MyHeader />
		<div class="page-left">
			<Home1 />
			<Home2 />
			<Home3 />
		</div>
		<div class="page-right">
			<Home4 :number="data1" />
			<Home5 @setData="setData1" />
			<Home6 />
		</div>
		<MyFooter />
		<!-- <Human /> -->
	</div>
</template>

<script>
import MyHeader from "@/views/components/MyHeader.vue"
import MyFooter from "./components/MyFooter.vue"
import MyMap from "@/views/components/map/changeing3D.vue"
// import Human from '@/views/components/Human'
import Home1 from "./components/home-1.vue"
import Home2 from "./components/home-2.vue"
import Home3 from "./components/home-3.vue"
import Home4 from "./components/home-4.vue"
import Home5 from "./components/home-5.vue"
import Home6 from "./components/home-6.vue"
export default {
	components: {
		MyHeader,
		MyFooter,
		MyMap,
		// Human,
		Home1,
		Home2,
		Home3,
		Home4,
		Home5,
		Home6,
	},
	data() {
		return {
			data1: 0
		}
	},
	methods: {
		setData1(data) {
			this.data1 = data
		}
	}
};
</script>

<style lang="scss" scoped>
@import "./components/chonghuan.scss";
.chonghuan-home {
	position: relative;
	height: 100%;
	.page-left,
	.page-right {
		width: $PageWidth1;
		height: calc(100% - #{$HeaderHeight});
		position: absolute;
		top: $HeaderHeight;
	}
	.page-left {
		left: $PageRange;
	}
	.page-right {
		right: $PageRange;
	}
}
</style>