<template>
    <div class="part-1 partBox">
        <!-- <MyTitle class="flexs" title="用户指标" /> -->
        <MyTitle class="flexs" title="综合指标" />
        <div class="box-content flex1 flex align-items flex-wrap">
            <div
                v-for="(item, i) in list"
                :key="'list' + i"
                class="item flex flex-direction width-50">
                <!-- <img class="item-c" :src="item.icon" alt="" /> -->
                <span class="item-c icon-bg-01">
                    <img :src="item.icon" alt="" />
                </span>
                <div class="item-l">{{ item.label }}</div>
                <div class="flex align-items-end ">
                    <span class="value">{{ item.value }}</span>
                    <!-- <dv-digital-flop style="height: 60px; width: auto" :config="dvDigitalFlopConfig(item)" /> -->
                    <span class="unit">{{ item.unit }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MyTitle from '@/views/components/MyTitle'
import { dataInterface } from "@/api/dataInterfaceApi"
import { fontSize36 } from './comm'
export default {
    components: {
        MyTitle
    },
    props: {
        number: {
            type: [String, Number],
            default: 0
        }
    },
    data() {
        return {
            list: [

                {
                    icon: require('@/assets/images/chonghuan/icon-40.png'),
                    label: '充电桩数量',
                    textAlign: 'left',
                    value: '',
                    unit: '个',
                    color: '#36FBFB'
                },
                {
                    icon: require('@/assets/images/chonghuan/icon-45.png'),
                    label: '充电站数量',
                    textAlign: 'left',
                    value: '',
                    unit: '座',
                    color: '#36FBFB'
                },
                {
                    icon: require('@/assets/images/chonghuan/icon-46.png'),
                    label: '充电枪数量',
                    textAlign: 'left',
                    value: '',
                    unit: '个',
                    color: '#36FBFB'
                },
                {
                    icon: require('@/assets/images/chonghuan/icon-42.png'),
                    label: '换电站数量',
                    textAlign: 'left',
                    value: '',
                    unit: '座',
                    color: '#36FBFB'
                },
            ]
        }
    },
    watch: {
        number(newV) {
            this.list[2].value = newV
        }
    },
    mounted() {
        let data = this.$route.query
        // 获取数据
        this.getData(data)
    },
	methods: {
        // 字体动态
        dvDigitalFlopConfig(item = {}) {
            let counts = parseFloat(item.value || 0)
            let config = {
                number: [parseFloat(counts)],
                // toFixed: 2,
                textAlign: item.textAlign || 'center',
                // content: `{nt}${item.unit}`,
                style: {
                    fill: item.color || '#fff',
                    fontSize: item.fontSize || fontSize36,
					fontFamily: 'DS-Digital-Bold'
                }
            }
            console.log('==config==', config)
            return config
        },
        // 获取数据
        async getData(data) {
            // this.yunyingzhibiao(data)

            // 用户属性
            let yonghushuxing = await this.yonghushuxing(data)
            let stationNum = await this.stationNum(data)
            
            // debugger
            this.list[0].value = yonghushuxing.charging_pile_num
            this.list[1].value = yonghushuxing.charging_station_num
            this.list[3].value = stationNum.total_station_num
            // this.list[3].value = yonghushuxing.vehicleViscidity
        },

        // 用户属性
        yonghushuxing () {
            return new Promise((resolve, rejiect) => {
                let params = {
                    "enCode": "TiDB-Energy-quanguo-zonghezhibiao"
                }
                dataInterface.dataFaceApi(params).then((res) => {
                    console.log('=========', res)
                    if(!res || !res.length || !res[0]) resolve({})
                    resolve(res[0])
                })
                .catch((err) => {
                    rejiect(err)
                    console.log(err)
                })
            })
        },

        // 充换首页-站数量
        stationNum (data = {}) {
            return new Promise((resolve, rejiect) => {
                let province = data.province || data.city || data.district || '000000'
                let params = {
                    'enCode': 'TiDB-xiazuan-stationNum',
                    '@station_type': '换电站',
                    '@ADCODE': province
                }
                dataInterface.dataFaceApi(params).then((res) => {
                    console.log('充换首页-站数量:=========', res)
                    if(!res || !res.length || !res[0]) resolve({})
                    resolve(res[0])
                })
                .catch((err) => {
                    rejiect(err)
                    console.log(err)
                })
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.part-1 {
    .box-content {
        padding: 15px 0 0;
        .item {
            font-size: $FontSize20;
            position: relative;
            padding-left: 110px;
            .item-c {
                width: 	105px;
                height: 80px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                > img {
                    display: block;
                    margin: auto;
                    margin-top: 12px;
                    transform: scale(1.2);
                }
            }
            .item-l {
                font-size: $FontSize30;
                color: #C6D1DB;
            }
            .value {
                font-size: $FontSize48;
                color: #01D8DD;
                @include ffamily-DS;
            }
            .unit {
                margin-left: 10px;
                padding-bottom: 12px;
            }
        }
    }
}
</style>