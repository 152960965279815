<template>
    <div class="part-2 partBox">
        <MyTitle class="flexs" title="充换电电量" type="2" />
        <div class="box-content flex1 flex">
            <MyEchart
                :visible="zhandianfuheShow"
                :id="'zhandianfuhe'"
                :options="zhandianfuheOptions" />
        </div>
    </div>
</template>

<script>
import MyTitle from '@/views/components/MyTitle'
import MyEchart from '@/views/components/charts/echarts'
import * as echarts from "echarts"
import { dataInterface } from "@/api/dataInterfaceApi"
export default {
    components: {
        MyTitle,
        MyEchart
    },
    data() {
        return {
            zhandianfuheShow: false,
            zhandianfuheOptions: {}
        }
    },
    mounted() {
        // 获取数据
        this.getData()
    },
	methods: {
        getData(data) {
            // 日均站点负荷率
            this.rijunzhandianfuhe(data)
        },
        
        // 日均站点负荷率
        rijunzhandianfuhe(data = {}) {
			let params = {}
            let province = data.province || data.city || data.district || '000000'
			params = {
				// 'enCode': !province ? 'TiDB-quanguo-zhandianrijunfuhelv' : 'TiDB-shengshiqu-zhandianfuhelv',
                'enCode': 'TiDB-quanguo-richonghuandiandianliang',
                '@province': province
			}
			dataInterface.dataFaceApi(params).then((res) => {
                console.log('chonghuan-home:日均站点符合率:TiDB-quanguo-richonghuandiandianliang', res)
				if (!res || !res.length) return
				// let data = res && res[0] || {}
                let xData = []
                let yData1 = []
                let yData2 = []
                res.map(item => {
                    xData.push(item.cur_date)
                    yData1.push(item.charge_elect)
                    yData2.push(item.exchange_elect)
                })
                this.zhandianfuheShow = false
                this.zhandianfuheOptions = {
                    title: {
                        text: '  kWh'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: xData
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: '换电电量',
                            type: 'bar',
                            barWidth: 20,
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
										offset: 0,
										color: 'rgba(10, 225, 183, 0.2)'
									},
									{
										offset: 1,
										color: 'rgba(10, 225, 183, 1)'
									}])
							},
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' %';
                                }
                            },
                            data: yData1
                        },
                        {
                            name: '充电电量',
                            type: 'bar',
                            barWidth: 20,
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
										offset: 0,
										color: 'rgba(11, 185, 229, 0.2)'
									},
									{
										offset: 1,
										color: 'rgba(11, 185, 229, 1)'
									}])
							},
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' %';
                                }
                            },
                            data: yData2
                        },
                        // {
                        //     name: '乘用车',
                        //     type: 'bar',
                        //     data: yData2
                        // }
                    ]
                }
                this.zhandianfuheShow = true
			})
			.catch((err) => {
				console.log(err)
			})
		},
    }
}
</script>

<style lang="scss" scoped>
.part-2 {
    .box-content {
        .item {
            width: 33%;
            font-size: $FontSize20;
            position: relative;
            .item-c {
                width: 	137px;
                height: 102px;
            }
            .item-l {
                font-size: $FontSize20;
                color: #C6D1DB;
            }
        }
    }
}
</style>