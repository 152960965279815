<template>
    <div class="part-1 partBox">
        <MyTitle class="flexs" title="运营指标" />
        <div class="box-content flex1 flex align-items flex-wrap">
            <div
                v-for="(item, i) in list"
                :key="'list' + i"
                class="item flex flex-direction width-50">
                <!-- <img class="item-c" :src="item.icon" alt="" /> -->
                <span class="item-c icon-bg-01">
                    <img :src="item.icon" alt="" :style="{width: item.width ? `${item.width}px` : 'auto'}" />
                </span>
                <div class="item-l">{{ item.label }}</div>
                <div class="flex align-items-end ">
                    <span class="value">{{ item.value }}</span>
                    <!-- <dv-digital-flop style="height: 60px; width: auto" :config="dvDigitalFlopConfig(item)" /> -->
                    <span class="unit">{{ item.unit }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MyTitle from '@/views/components/MyTitle'
import { dataInterface } from "@/api/dataInterfaceApi"
import { fontSize48 } from './comm'
export default {
    components: {
        MyTitle
    },
    data() {
        return {
            list: [
                {
                    label: '月充换电次数',
                    value: '0',
                    icon: require('@/assets/images/chonghuan/icon-40.png'),
                    color: '#01D8DD',
                    textAlign: 'left',
                    unit: '次'
                },
                {
                    label: '月累计碳减排',
                    value: '0',
                    icon: require('@/assets/images/chonghuan/icon-7.png'),
                    color: '#01D8DD',
                    textAlign: 'left',
                    unit: 'cc'
                },
                {
                    label: '月换电里程',
                    value: '0',
                    icon: require('@/assets/images/chonghuan/icon-1.png'),
                    color: '#01D8DD',
                    textAlign: 'left',
                    width: 25,
                    unit: 'km'
                },
                {
                    label: '月充换电电量',
                    value: '0',
                    icon: require('@/assets/images/chonghuan/icon-44.png'),
                    color: '#01D8DD',
                    textAlign: 'left',
                    unit: 'kWh'
                }
            ]
        }
    },
    mounted() {
        // 获取数据
        this.getData()
    },
	methods: {
        // 字体动态
        dvDigitalFlopConfig(item = {}) {
            let counts = parseFloat(item.value || 0)
            console.log('fontSize48=====', fontSize48)
            let config = {
                number: [parseFloat(counts)],
                // toFixed: 2,
                textAlign: item.textAlign || 'center',
                // content: `{nt}${item.unit}`,
                style: {
                    fill: item.color || '#fff',
                    fontSize: item.fontSize || fontSize48,
					fontFamily: 'DS-Digital-Bold'
                }
            }
            console.log('==config==', config)
            return config
        },
        // 获取数据
        getData() {
            let data = this.$route.query
            this.chonghuanyunyingzhibiao(data)
        },
        async chonghuanyunyingzhibiao(data) {
            let params = {}
            let province = data.province || data.city || data.district || '000000'
			params = {
				// 'enCode': !province ? 'TiDB-quanguo-yunyingzhibiaoshouru' : 'TiDB-shengshiqu-yunyingzhibiaoshouru',
                'enCode': 'TiDB-shengshiqu-chonghuanyunyingzhibiao-new',
                '@province': province
			}
            let resData = await dataInterface.dataFaceApi(params)
            console.log('chonghuan-home:考核指标', resData)
            if(resData && resData.length) {
                let data = resData[0] || {}
                this.list[0].value = data.new_exchange_or_charge_num
                this.list[1].value = data.carbon_emission_reduction
                this.list[2].value = data.new_exchange_or_charge_mileage
                this.list[3].value = parseInt(data.new_exchange_or_charge_elect || 0)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.part-1 {
    .box-content {
        padding: 15px 0 0 0;
        .item {
            font-size: $FontSize30;
            position: relative;
            padding-left: 110px;
            .item-c {
                width: 	105px;
                height: 90px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                > img {
                    display: block;
                    margin: auto;
                    margin-top: 12px;
                    transform: scale(1.2);
                }
            }
            .item-l {
                // font-size: $FontSize20;
                color: #C6D1DB;
            }
            .value {
                font-size: $FontSize48;
                color: #01D8DD;
                @include ffamily-DS;
            }
            .unit {
                margin-left: 10px;
                padding-bottom: 10px;
            }
        }
    }
}
</style>