<template>
    <div class="part-6 partBox">
        <!-- <MyTitle class="flexs" title="综合指标" /> -->
        <MyTitle class="flexs mt-20" title="运营车型TOP8" type="2" />
        <div class="box-content flex1 flex">
            <MyEchart
                :visible="rightcategorySiteShow"
                :id="'rightcategorySite'"
                :options="rightcategorySiteOptions" />
        </div>
    </div>
</template>

<script>
import MyTitle from '@/views/components/MyTitle'
import MyEchart from '@/views/components/charts/echarts'
import * as echarts from "echarts"
import { dataInterface } from "@/api/dataInterfaceApi"
export default {
    components: {
        MyTitle,
        MyEchart
    },
    data() {
        return {
            rightcategorySiteShow: false,
            rightcategorySiteOptions: {}
        }
    },
    mounted() {
        // 获取数据
        this.getData()
    },
	methods: {
        getData(data) {
            // 全国-站型运营数量TOP10
            this.zhanxingyunyingshuliang(data)
        },
        
        // 全国-站型运营数量TOP10
        zhanxingyunyingshuliang() {
            let params = {
                "enCode": "TiDB-Energy-quanguo-chexingyunyingshuliang"
            }
            dataInterface.dataFaceApi(params).then((res) => {
                console.log('chonghuan-home：全国-站型运营数量TOP10：TiDB-Energy-quanguo-zhanxingyunyingshuliang', res)
                if(!res || !res.length || !res[0]) return
                // resolve(res[0])
                let xData = []
                let yData1 = []
                res.sort(function(a, b) {
                    return b.vehicleBrandCount - a.vehicleBrandCount
                })
                // let num = 0
                // res.map(item => {
                //     num = num + parseInt(item.vehicleBrandCount, 10)
                // })
                // this.$emit('setData', num)
                res = res.splice(0,8)
                console.log(res)
                res.map(item => {
                    xData.push(item.vehicleBrand )
                    yData1.push(parseFloat(item.vehicleBrandCount, 10))
                })

                this.rightcategorySiteShow = false
                this.rightcategorySiteOptions = {
                    title: {
                        text: '     辆',
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: xData,
                        // axisLabel: {
                        //     rotate: 45
                        // }
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: '',
                            type: 'bar',
                            barWidth: 20,
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
										offset: 0,
										color: 'rgba(10, 225, 183, 0)'
									},
									{
										offset: 1,
										color: 'rgba(15, 255, 208, 1)'
									}])
							},
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' %';
                                }
                            },
                            data: yData1
                        },
                        // {
                        //     name: '乘用车',
                        //     type: 'bar',
                        //     data: yData2
                        // }
                    ]
                }
                this.rightcategorySiteShow = true
            })
            .catch((err) => {
                console.log(err)
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.part-6 {
    .box-content {
        .item {
            width: 33%;
            font-size: $FontSize20;
            position: relative;
            .item-c {
                width: 	137px;
                height: 102px;
            }
            .item-l {
                font-size: $FontSize20;
                color: #C6D1DB;
            }
        }
    }
}
</style>