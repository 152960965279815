<template>
    <div class="my-footer">
            <!-- :slides-per-view="3"
            :space-between="50"
            @swiper="onSwiper"
            @slideChange="onSlideChange"> -->
        <swiper :options="swiperOptions">
            <swiper-slide
                v-for="(item, i) in list"
                :key="'list' + i">
                <div 
                    class="item flex1 flex align-items flex-direction">
                    <span class="item-v"><span>{{ item.value }}</span>{{ item.unit }}</span>
                    <div class="item-l">{{ item.label }}</div>
                    <img class="item-c" :src="item.icon" alt="" />
                    <img class="item-c item-c-at" :src="item.iconAt" alt="" />
                    <!-- <dv-digital-flop style="height: 100px; width: 100%" :config="dvDigitalFlopConfig(item)" /> -->
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
// import { fontSize48 } from './comm'
import { dataInterface } from "@/api/dataInterfaceApi"
export default {
    data() {
        return {
            list: [
                {
                    icon: require('@/assets/images/chonghuan/icon-8.png'),
                    iconAt: require('@/assets/images/chonghuan/icon-8-at.png'),
                    label: '累计充换电站数',
                    value: '',
                    unit: '座',
                    color: '#43FFB9'
                },
                {
                    icon: require('@/assets/images/chonghuan/icon-8.png'),
                    iconAt: require('@/assets/images/chonghuan/icon-8-at.png'),
                    label: '累计充换电车辆',
                    value: '',
                    unit: '辆',
                    color: '#43FFB9'
                },
                {
                    icon: require('@/assets/images/chonghuan/icon-9.png'),
                    iconAt: require('@/assets/images/chonghuan/icon-9-at.png'),
                    label: '累计入网司机数',
                    value: '',
                    unit: '人',
                    color: '#36FBFB'
                },
                {
                    icon: require('@/assets/images/chonghuan/icon-11.png'),
                    iconAt: require('@/assets/images/chonghuan/icon-11-at.png'),
                    label: '累计充换电电量',
                    value: '',
                    unit: '度',
                    color: '#179DFF'
                },
                {
                    icon: require('@/assets/images/chonghuan/icon-12.png'),
                    iconAt: require('@/assets/images/chonghuan/icon-12-at.png'),
                    label: '累计充换电里程',
                    value: '',
                    unit: '公里',
                    color: '#179DFF'
                },
                {
                    icon: require('@/assets/images/chonghuan/icon-10.png'),
                    iconAt: require('@/assets/images/chonghuan/icon-10-at.png'),
                    label: '累计充换电次数',
                    value: '',
                    unit: '次',
                    color: '#36FBFB'
                }
                // {
                //     label: '充电站数量',
                //     value: '829',
                //     icon: require('@/assets/images/chonghuan/icon-8.png'),
                //     iconAt: require('@/assets/images/chonghuan/icon-8-at.png'),
                //     unit: '座'
                // },
                // {
                //     label: '换电站数量',
                //     value: '2782',
                //     icon: require('@/assets/images/chonghuan/icon-9.png'),
                //     iconAt: require('@/assets/images/chonghuan/icon-9-at.png'),
                //     unit: '座'
                // },
                // {
                //     label: '累计充换电电量',
                //     value: '2782',
                //     icon: require('@/assets/images/chonghuan/icon-11.png'),
                //     iconAt: require('@/assets/images/chonghuan/icon-11-at.png'),
                //     unit: 'kWh'
                // },
                // {
                //     label: '累计换电里程',
                //     value: '2782',
                //     icon: require('@/assets/images/chonghuan/icon-12.png'),
                //     iconAt: require('@/assets/images/chonghuan/icon-12-at.png'),
                //     unit: 'km'
                // },
                // {
                //     label: '充换电次数',
                //     value: '682',
                //     icon: require('@/assets/images/chonghuan/icon-10.png'),
                //     iconAt: require('@/assets/images/chonghuan/icon-10-at.png'),
                //     unit: '万'
                // }

            ],
            swiperOptions: {
                loop: true,
                autoplay: {
                    delay: 2000,
                    disableOnInteraction: false
                },
                speed: 500,
                slidesPerView: 5,
                // spaceBetween: 40,
                centeredSlides: true,
                watchSlidesProgress: true,
                on: {
                    setTranslate: function () {
                        let slides = this.slides;
                        for (let i = 0; i < slides.length; i++) {
                            let slide = slides.eq(i)
                            let progress = slides[i].progress
                            slide.css({'opacity': '', 'background': ''})
                            slide.transform('')
                            slide.css('opacity',(1-Math.abs(progress) / 6))
                            slide.transform('translate3d(0,'+ Math.abs(progress) * 20 + 'px, 0)')
                        }
                    },
                    setTransition: function (transition) {
                        for (let i = 0; i < this.slides.length; i++) {
                            let slide = this.slides.eq(i)
                            slide.transition(transition)
                        }
                    }
                },
            }
        }
    },
    mounted() {
        // 获取数据
		this.getData()
    },
    methods: {
        // 获取数据
		async getData() {
            // 累计充换电站数量
            let totalNum = await this.getquanguoTotalNum()
            // 累计入网司机数量
            let totalUserNum = await this.getquanguoTotalUserNum()
            // 累计充换电车辆数/充换电次数/充换电电量/充换电里程
            let leijichonghuandianxinxi = await this.quanguoleijichonghuandianxinxi()
            
            // debugger
            this.list[0].value = totalNum.station_num
            this.list[1].value = leijichonghuandianxinxi.exchangeOrChargeVehicleNum
            this.list[2].value = totalUserNum.userNum
            this.list[3].value = (parseFloat(leijichonghuandianxinxi.exchangeOrChargeElect, 10) / 10000).toFixed(0) + '万'
            this.list[4].value = (parseFloat(leijichonghuandianxinxi.exchangeOrChargeMileage, 10) / 10000).toFixed(0) + '万'
            this.list[5].value = (parseFloat(leijichonghuandianxinxi.exchangeOrChargeNum, 10) / 10000).toFixed(0) + '万'
        },
        // 累计充换电站数量
        getquanguoTotalNum() {
            return new Promise((resolve, rejiect) => {
                let params = {
                    "enCode": "TiDB-Energy-quanguo-totalNum"
                }
                dataInterface.dataFaceApi(params).then((res) => {
                    console.log('=========', res)
                    if(!res || !res.length || !res[0]) resolve({})
                    resolve(res[0])
                })
                .catch((err) => {
                    rejiect(err)
                    console.log(err)
                })
            })
        },
        // 累计入网司机数量
        getquanguoTotalUserNum() {
            return new Promise( (resolve, rejiect) => {
                let params = {
                    "enCode": "TiDB-Energy-quanguo-totalUserNum"
                }
                dataInterface.dataFaceApi(params).then((res) => {
                    console.log('=========', res)
                    if(!res || !res.length || !res[0]) resolve({})
                    resolve(res[0])
                })
                .catch((err) => {
                    rejiect(err)
                    console.log(err);
                })
            })
        },
        // 累计充换电车辆数/充换电次数/充换电电量/充换电里程
        quanguoleijichonghuandianxinxi() {
            return new Promise( (resolve, reject) => {
                let params = {
                    "enCode": "TiDB-Energy-quanguo-leijichonghuandianxinxi",
					"@province":'000000'
                }
                dataInterface.dataFaceApi(params).then((res) => {
                    console.log('=========', res)
                    if(!res || !res.length || !res[0]) resolve({})
                    resolve(res[0])
                })
                .catch((err) => {
                    reject(err)
                    console.log(err);
                })
            })
        },
    }
    
}
</script>

<style lang="scss" scoped>
@import './chonghuan.scss';
.my-footer {
    .item {
        font-size: $FontSize20;
        position: relative;
        .item-c {
            width: 	125px;
            height: 114px;
            margin-top: -10px;
        }
        .item-c-at {
            width: 	180px;
            height: 159px;
            margin-top: -10px;
        }
        .item-v {
            white-space: nowrap;
            font-size: $FontSize20;
            height: 70px;
            > span {
                font-size: $FontSize48;
                @include ffamily-HT;
            }
        }
        .item-l {
            font-size: $FontSize28;
            color: #C6D1DB;
        }
    }
}
</style>

<style lang="scss">
.my-footer {
    .swiper-container {
        height: 100%;
        .swiper-wrapper {
            margin-top: 40px;
        }
    }
    .swiper-slide {
        .item-c-at {
            display: none;
        }
    }
    .swiper-slide-active {
        .item {
            .item-c {
                display: none;
            }
            .item-c-at {
                display: block;
            }
            .item-l {
                font-size: $FontSize36;
            }
            .item-v {
                height: 120px;
                margin-top: -80px;
                > span {
                    color: #00FDF4;
                    font-size: $FontSize96;
                }
            }
        }
    }
}
</style>