<template>
    <div class="part-5 partBox">
        <MyTitle class="flexs" title="充电桩&充电枪统计" type="2" />
        <div class="box-content flex1 flex">
            <myEchart
                :visible="yonghuhuoyueShow"
                :id="'yonghuhuoyue'"
                :options="yonghuhuoyueOptions" />
        </div>
    </div>
</template>

<script>
import MyTitle from '@/views/components/MyTitle'
import MyEchart from '@/views/components/charts/echarts'
import * as echarts from "echarts"
import { dataInterface } from "@/api/dataInterfaceApi"
export default {
    components: {
        MyTitle,
        MyEchart
    },
    data() {
        return {
            yonghuhuoyueShow: false,
            yonghuhuoyueOptions: {}
        }
    },
    mounted() {
        // 获取数据
        this.getData()
    },
	methods: {
        getData(data) {
            // 用户活跃趋势
            this.yonghuhuoyuequshi(data)
        },
        
        // 用户活跃趋势
        yonghuhuoyuequshi(data = {}) {
			let params = {}
            let province = data.province || data.city || data.district || ''
			params = {
				// 'enCode': !province ? 'TiDB-quanguo-yonghuxinzenghuoyuetu' : 'TiDB-shengshiqu-yonghuxinzenghuoyuetu',
                'enCode': 'TiDB-Energy-quanguo-pile-gun-info',
                '@province': province
			}
			dataInterface.dataFaceApi(params).then((res) => {
				if (!res || !res.length) return
				// let data = res && res[0] || {}
                let xData = []
                let yData1 = []
                let yData2 = []
                res.map(item => {
                    xData.push(item.cur_date)
                    yData1.push(item.pile_num)
                    yData2.push(item.gun_num)
                })
                this.$emit('setData', res[res.length - 1].gun_num)
                this.yonghuhuoyueShow = false
                this.yonghuhuoyueOptions = {
                    title: {
                        text: '     个',
                    },
                    color: ['#8bcfd0', '#f2bd4c'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: xData
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: '充电枪数量',
                            type: 'line',
                            smooth: true,
                            showSymbol: false,
                            label: {
                                show: true,
                                position: 'top'
                            },
                            lineStyle: {
                                width: 2,
                                color: '#8bcfd0'
                            },
                            areaStyle: {
                                opacity: 0.8,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgba(139, 207, 208, 1)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(134, 201, 203, 0)'
                                    }
                                ])
                            },
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' 次';
                                }
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: yData2
                        },
                        {
                            name: '充电桩数量',
                            type: 'line',
                            smooth: true,
                            showSymbol: false,
                            label: {
                                show: true,
                                position: 'top'
                            },
                            lineStyle: {
                                width: 2,
                                color: '#f2bd4c'
                            },
                            areaStyle: {
                                opacity: 0.8,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgba(235, 190, 77, 1)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(235, 190, 76, 0)'
                                    }
                                ])
                            },
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' 次';
                                }
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: yData1
                        },
                    ]
                }
                this.yonghuhuoyueShow = true
			})
			.catch((err) => {
				console.log(err)
			})
		},
    }
}
</script>

<style lang="scss" scoped>
.part-5 {
    .box-content {
        .item {
            width: 33%;
            font-size: $FontSize20;
            position: relative;
            .item-c {
                width: 	137px;
                height: 102px;
            }
            .item-l {
                font-size: $FontSize20;
                color: #C6D1DB;
            }
        }
    }
}
</style>