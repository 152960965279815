<template>
  <!-- <div id="container"></div> -->
  <amap
    :center.sync="center"
    :zoom="zoom"
    :style="{ opacity: opacityShow }"
    @mousewheel="mousewheelChange"
    @mouseover="mouseoverChange"
  >
    <amap-map-type
      :visible="visible"
      :showTraffic="false"
      :showRoad="false"
      :defaultType="1"
    />
    <div v-for="(item, index) in clusterData" :key="index">
      <amap-marker
        :position.sync="item.value"
        :anchor="anchor"
        :offset="[-50, -50]"
      >
        <!-- <div
            v-if="!item.station_type"
            v-html="item.centerHtml"
            @click="clickArea(item)"
          ></div> -->
        <div
          class="mapBg flex flex-direction align-items justify-content-between"
          v-if="!item.station_type"
          @click="clickArea(item)"
        >
          <!--  弹出1.0   搜索 弹窗1.0 可看到css  已注释 -->
          <div class="marsbg flex flex-direction align-items justify-content">
            <div class="flex flex-direction align-items">
              <div
                style="height: 30px; width: 100%; line-height: 30px"
                class="flex"
              >
                <div
                  class="huandianzhan-icon huandianzhan"
                  style="margin-top: 5px; margin-left: 10px"
                  v-if="item.huandianzhan > 0"
                ></div>
                <div
                  class="itemNumber"
                  style="padding: 0px 19px 0 38px; color: #41d1ff"
                  v-if="item.huandianzhan > 0"
                >
                  {{ item.huandianzhan }}
                </div>
                <div
                  class="huandianzhan-icon chongdianzhuang"
                  style="margin-top: 5px"
                  v-if="item.chongdianzhuang > 0"
                ></div>
                <div
                  class="itemNumber"
                  style="padding: 0px 19px 0 38px; color: #74f9f3"
                  v-if="item.chongdianzhuang > 0"
                >
                  {{ item.chongdianzhuang }}
                </div>
              </div>
              <div
                style="
                  height: 30px;
                  width: 100%;
                  font-size: 26px;
                  text-align: center;
                "
              >
                {{ item.name }}
              </div>
            </div>
            <div></div>
          </div>
          <!-- <div class="marsBoxBg flex align-items">
                <span>江苏省</span>
                <span>111</span>
                <span>111</span>
            </div> -->
          <div class="marsblue"></div>
          <div class="marsbottom"></div>
        </div>
        <div v-else @click="clickArea(item)">
          <div class="marker">
            <div class="pop-content flex flex-direction align-items">
              <div class="text-xsss text-left">{{ item.stationName }}</div>
              <div class="pop-img flex align-center justify-center">
                <img
                  :src="`${
                    item.station_type === '换电站' ? car_bg : car_chongdian
                  }`"
                />
              </div>
              <div class="text-xsss text-center">
                可换{{ item.houseTotalBatteries || 0 }}，排队{{
                  item.lineUpNum || 0
                }}
              </div>
            </div>
            <div class="diamand flex justify-center justify-content">
              <img class="diamand1" :src="`${area_diamond}`" />
              <img class="diamand2" :src="`${area_diamond2}`" />
            </div>
          </div>
        </div>
      </amap-marker>
    </div>
  </amap>
</template>
  
  <script>
// import AMapLoader from '@amap/amap-jsapi-loader'
import { dataInterface } from "@/api/dataInterfaceApi";
import area_diamond from "@/assets/images/index/area_diamond.png";
import area_diamond2 from "@/assets/images/index/area_diamond2.png";
import car_bg from "@/assets/images/index/car-bg1.png";
import car_chongdian from "@/assets/images/index/car_chongdian.jpg";
export default {
  components: {},
  data() {
    return {
      plugin: [
        {
          pName: "MapType",
          defaultType: 1, //0代表默认，1代表卫星
          showTraffic: false, // 路网
          showRoad: true, // 路况
        },
      ],
      center: [110, 32],
      clusterData: [],
      anchor: "center",
      visible: false,
      params: {
        longitudeStart: "",
        latitudeStart: "",
        vehicleVin: "",
      },
      listDatas: [],
      car: {
        // path[0].slice(0)
        // pos: path[0].slice(0), // 小车icon的坐标，为空不能设置该key.
        // angle: (Math.atan2(dy, dx) * 180) / Math.PI,
        icon: {
          image: "https://jimnox.gitee.io/amap-vue/assets/car.png",
          imageSize: [26, 50],
        },

        offset: [-13, -25],
      },
      geoJson: {
        features: [],
      },
      zoom: 6,
      area_diamond: area_diamond,
      area_diamond2: area_diamond2,
      car_bg: car_bg,
      car_chongdian: car_chongdian,
      opacityShow: 0,
      isShow: false,
    };
  },
  created() {
    this.zoom = this.$route.query.zoom * 1 || 6;
    this.center = this.$route.query.center || [110, 32];
    let cityCode = this.$route.query.province
      ? this.$route.query.province
      : this.$route.query.city;
    console.log(this.$route.query.level, "级别");
    if (this.$route.query.level == "province") {
      console.log("当前-省");
      this.selectProvinceStationInfo(cityCode);
    } else if (
      this.$route.query.level == "city" &&
      this.$route.query.name != "石河子市"
    ) {
      console.log("当前-市", cityCode);
      this.selectCityStationInfo(cityCode);
    } else if (
      this.$route.query.level == "district" ||
      this.$route.query.name == "石河子市"
    ) {
      console.log("当前-区");
      this.selectDistrictStationInfo(cityCode);
      // this.$router.push({
      //     path: "/newVehicleControl",
      //     query: {
      //       city: item.cityCode,
      //       // name: item.name,
      //       cityName: item.name,
      //       value:item.value,
      //       zoom: 13,
      //       level: item.level,
      //       center: item.value
      //     },
      //   });
    } else {
      this.init();
    }
  },
  mounted() {},
  methods: {
    // clickMyVehical(item) {
    // 	console.log(item)
    // 	localStorage.setItem('newVehicleNum', item.vin)
    // 	if(item.vehicleType === '乘用车') {
    // 		this.$router.push({
    // 		    path: '/singleVehicle',
    // 		    query: {}
    // 		})
    // 	} else {
    // 		this.$router.push({
    // 		    path: '/singlezhongka',
    // 		    query: {}
    // 		})
    // 	}
    // },
    mouseoverChange(e) {
      console.log(e);
    },
    mousewheelChange(e) {
      console.log(e, "地图缩放");
    },
    clickArea(item) {
      console.log(item, "这是点击的item");
      if (item.level == "province") {
        this.$router.push({
          path: "/chonghuan-home",
          query: {
            province: item.cityCode,
            name: item.name,
            zoom: 8,
            level: item.level,
            center: item.value,
          },
        });
      } else if (item.level == "city" && item.name != "石河子市") {
        this.$router.push({
          path: "/chonghuan-home",
          query: {
            city: item.cityCode,
            name: item.name,
            zoom: 13,
            level: item.level,
            center: item.value,
            value: item.value,
          },
        });
      } else if (item.level == "district" || item.name == "石河子市") {
        // this.selectDistrictStationInfo(item.cityCode);
        this.$router.push({
          path: "/chonghuan-home",
          query: {
            city: item.cityCode,
            name: item.name,
            zoom: 13,
            level: item.level,
            center: item.value,
            value: item.value,
          },
        });
      } else if (item.station_type) {
        this.$router.push({
          path: "/chonghuan-station",
          query: {
            stationId: item.id,
            type: item.station_type === "换电站" ? 1 : 2,
          },
        });
      }
    },
    init(city, vehicleVin) {
      let that = this;
      console.log(city);
      that.center = [110, 32];
      this.params.vehicleVin = vehicleVin ? vehicleVin : "";
      this.selectCountryStationInfo();
      //   if (city) {
      //     let cityName = city ? city : "贵阳";
      //     AMap.plugin("AMap.Geocoder", function () {
      //       var geocoder = new AMap.Geocoder({
      //         city: "", //城市设为北京，默认：“全国”
      //       });
      //       geocoder.getLocation(cityName, function (status, result) {
      //         if (status === "complete" && result.geocodes.length) {
      //           var lnglat = result.geocodes[0].location;
      //           // that.params.longitudeStart = lnglat.lng
      //           // that.params.latitudeStart = lnglat.lat
      //           let unmber = 0.1;
      //           that.params.longitudeStart = lnglat.lng - unmber;
      //           that.params.longitudeEnd = lnglat.lng + unmber;
      //           that.params.latitudeStart = lnglat.lat - unmber;
      //           that.params.latitudeEnd = lnglat.lat + unmber;
      //           that.center = [lnglat.lng, lnglat.lat];
      //           console.log(that.center);
      //           that.getCars();
      //         } else {
      //           // log.error('根据地址查询位置失败');
      //         }
      //       }); /*  */
      //     });
      //   }
    },
    sumArrayFields(arr) {
      let sums = {};
      for (let i = 0; i < arr.length; i++) {
        // let provinceName =  arr[i].provinceName
        let province = arr[i].province || arr[i].city || arr[i].district;
        let chongdianzhuang = parseInt(arr[i].chongdianzhuang);
        let huandianzhan = parseInt(arr[i].huandianzhan);

        if (sums[province]) {
          sums[province].chongdianzhuang += chongdianzhuang;
          sums[province].huandianzhan += huandianzhan;
          // sums[province].provinceName = provinceName
        } else {
          sums[province] = {
            chongdianzhuang: chongdianzhuang,
            huandianzhan: huandianzhan,
          };
        }
      }

      let result = [];
      for (let province in sums) {
        result.push({
          // provinceName: sums.provinceName,
          province: province,
          chongdianzhuang: sums[province].chongdianzhuang,
          huandianzhan: sums[province].huandianzhan,
        });
      }

      return result;
    },
    sumArrayFields1(arr) {
      let sums = {};
      for (let i = 0; i < arr.length; i++) {
        // let provinceName =  arr[i].provinceName
        let city = arr[i].city;
        let chongdianzhuang = parseInt(arr[i].chongdianzhuang);
        let huandianzhan = parseInt(arr[i].huandianzhan);

        if (sums[city]) {
          sums[city].chongdianzhuang += chongdianzhuang;
          sums[city].huandianzhan += huandianzhan;
          // sums[province].provinceName = provinceName
        } else {
          sums[city] = {
            chongdianzhuang: chongdianzhuang,
            huandianzhan: huandianzhan,
          };
        }
      }

      let result = [];
      for (let city in sums) {
        result.push({
          // provinceName: sums.provinceName,
          city: city,
          chongdianzhuang: sums[city].chongdianzhuang,
          huandianzhan: sums[city].huandianzhan,
        });
      }

      return result;
    },
    sumArrayFields2(arr) {
      let sums = {};
      for (let i = 0; i < arr.length; i++) {
        // let provinceName =  arr[i].provinceName
        let district = arr[i].district;
        let chongdianzhuang = parseInt(arr[i].chongdianzhuang);
        let huandianzhan = parseInt(arr[i].huandianzhan);

        if (sums[district]) {
          sums[district].chongdianzhuang += chongdianzhuang;
          sums[district].huandianzhan += huandianzhan;
          // sums[province].provinceName = provinceName
        } else {
          sums[district] = {
            chongdianzhuang: chongdianzhuang,
            huandianzhan: huandianzhan,
          };
        }
      }

      let result = [];
      for (let district in sums) {
        result.push({
          // provinceName: sums.provinceName,
          district: district,
          chongdianzhuang: sums[district].chongdianzhuang,
          huandianzhan: sums[district].huandianzhan,
        });
      }

      return result;
    },
    //获取全国站点数据
    selectCountryStationInfo() {
      let params = {
        enCode: "TiDB-guojiaditu-zhandianzhuangtai", //TiDB-全国地图
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res) return;
          console.log("充换电全国地图", res);
          let arr = res;
          console.log(this.sumArrayFields(arr), "处理后的数据");
          // console.log(arr, "处理的数据");
          // this.provinceStationInfo = res;
          // this.listDatas = res[0];
          this.getGeoJson(100000, "", this.sumArrayFields(arr));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取省级站点数据
    selectProvinceStationInfo(updateParams) {
      console.log(updateParams, "updateParams");
      //获取省的站点信息
      let params = {
        enCode: "TiDB-shengjiditu-zhandianzhuangtai", //TiDB-全国地图
        "@province": updateParams,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res) return;
          let arr = res;
          console.log(arr, "深的数据");
          // this.getGeoJson(updateParams.province, "province", res);
          this.getGeoJson(updateParams, "province", this.sumArrayFields1(arr));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取区级站点数据
    selectCityStationInfo(updateParams) {
      console.log(updateParams, "城市编码");
      //获取省的站点信息
      let params = {
        enCode: "TiDB-shijiditu-zhandianzhuangtai", //TiDB-全国地图
        "@province": updateParams,
      };
      console.log(params, "这是传的值");
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res) return;
          let arr = res;
          // this.getGeoJson(adcode, type, res);
          console.log(res, "这是那个区级");
          this.getGeoJson(updateParams, "city", this.sumArrayFields2(arr));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取区级换电站数据
    selectDistrictStationInfo(curAdcode) {
      let params = {
        enCode: "TiDB-zhandian-zhandianjingweidu", //TiDB-全国地图
        "@province": curAdcode,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res) return;
          //   this.stationList = res || [];

          this.clusterData = res.map((item) => {
            return {
              value: [item.longitude, item.latitude],
              houseTotalBatteries: item.houseTotalBatteries,
              id: item.id,
              stationName: item.stationName,
              station_type: item.station_type,
            };
          });
          console.log(this.clusterData, "区级站点数据");
          setInterval(() => {
            this.opacityShow = 1;
          }, 2500);
          // this.getGeoJson1(adcode, curAdcode, res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getGeoJson(adcode, type, stationInfo) {
      setInterval(() => {
        this.opacityShow = 1;
      }, 2500);
      // type： province||city
      let that = this;
      /* eslint-disable */
      AMapUI.loadUI(["geo/DistrictExplorer"], (DistrictExplorer) => {
        var districtExplorer = new DistrictExplorer();
        districtExplorer.loadAreaNode(adcode, function (error, areaNode) {
          if (error) {
            console.error(error);
            return;
          }
          let Json = areaNode.getSubFeatures();
          if (Json.length > 0) {
            console.log(Json, "Json");
            that.geoJson.features = Json;
          } else if (Json.length === 0) {
            that.geoJson.features = that.geoJson.features.filter(
              (item) => item.properties.adcode == adcode
            );
            if (that.geoJson.features.length === 0) return;
          }
          that.getMapData(type, stationInfo);
        });
      });
    },
    //获取数据
    async getMapData(type, stationInfo) {
      let _this = this;
      // stationInfo =
      //   stationInfo.reduce((res, item, index, arr) => {
      //     if (!res[item.province || item.district || item.city]) {
      //       res[item.province || item.district || item.city] = item;
      //     } else {
      //       res[item.province || item.district || item.city] = {
      //         ...item,
      //         chongdianzhuang: `${
      //           res[item.province || item.district || item.city].chongdianzhuang
      //         },${item.chongdianzhuang}`,
      //         huandianzhan: `${
      //           res[item.province || item.district || item.city].huandianzhan
      //         },${item.huandianzhan}`,
      //         status: `${
      //           res[item.province || item.district || item.city].status
      //         },${item.status}`,
      //       };
      //     }
      //     if (index === arr.length - 1) {
      //       return Object.values(res);
      //     }
      //     return res;
      //   }, {}) || [];

      let mapData = this.geoJson.features.map((item) => {
        //可以改造数据
        let data = {
          name: item.properties.name,
          //数据value 需要改
          datas: 0,
          position: [],
          cityCode: item.properties.adcode,
          level: item.properties.level, //省 province ，市city ，区district
        };
        let currentData = [];
        currentData = stationInfo.filter(
          (item) =>
            (item.province || item.district || item.city) == data.cityCode
        );
        console.log(currentData.length, "00000000000");
        if (currentData && currentData.length > 0) {
          data.datas =
            parseInt(currentData[0].huandianzhan) +
            parseInt(currentData[0].chongdianzhuang);
          data.chongdianzhuang = currentData[0].chongdianzhuang;
          data.huandianzhan = currentData[0].huandianzhan;
          data.status = currentData[0].status || "";
          data.value = item.properties.center;
        }

        console.log("处理的data", data);
        return data;
      });

      console.log("充换电的mapData", mapData);
      // mapData.forEach((item,index) => {
      //     if(item.chongdianzhuang || item.huandianzhan){
      //     }else{
      //       item.splice(index,1)
      //     }
      // });
      let newArr = mapData.filter(item => item.huandianzhan || item.chongdianzhuang);
      this.clusterData = newArr;
      //   console.log("要渲染的数据", this.clusterData);
      // this.clusterData.forEach((item, index) => {
      //   console.log(item, "循环item");
      //   if (!item.huandianzhan && !item.huandianzhan) {
      //     return;
      //   }
      //   var innerHtml = "";
      //   var centerHtml = "";
      //   var statusText = "";
      //   var statusBg = "";
      //   var chongdianzhuang =
      //     (item.chongdianzhuang && item.chongdianzhuang.split(",")) || "";
      //   var huandianzhan =
      //     (item.huandianzhan && item.huandianzhan.split(",")) || "";

      //   var status = (item.status && item.status.split(",")) || "";
      //   if (status) {
      //     for (let i = 0; i < status.length; i++) {
      //       if (status[i] == "暂停营业") {
      //         statusBg = "yellow";
      //         statusText = "停站";
      //       } else if (status[i] == "营业中") {
      //         statusBg = "green";
      //         statusText = "运营";
      //       } else if (status[i] == "建设中") {
      //         statusBg = "white";
      //         statusText = "建设";
      //       }
      //       innerHtml += `<div class="m-r-40 m-t-20 font-20 flex align-center">
      //                       <div class="font-15 m-r-15 flex align-center ${statusBg}" style="white-space:nowrap;">${statusText}:</div>
      //                       <div class="huandianzhan-icon huandianzhan flex align-center ${statusBg}">${
      //         huandianzhan[i] == 0 ? "" : huandianzhan[i]
      //       }</div>
      //                       <div class="huandianzhan-icon chongdianzhuang flex align-center ${statusBg}">${
      //         chongdianzhuang[i] == 0 ? "" : chongdianzhuang[i]
      //       }</div>
      //                     </div>`;
      //     }
      //     centerHtml += `<div style="font-size:0;">
      //                           <div class="marsMapBgTop1 flex flex-direction justify-center">
      //                             <div class="m-r-40 font-20" style="margin-top:-10px">${item.name}:</div>
      //                           </div>
      //                           <div class="marsMapBg1 flex flex-direction">
      //                               ${innerHtml}
      //                             </div>
      //                             <div class="marsMapBgBottom1"></div>
      //                             </div>`;
      //   } else {
      //     centerHtml += `<div class="marsMapBgAll flex flex-direction">
      //             <div class="m-r-35 font-20 m-b-20">
      //               ${item.name}
      //             </div>
      //             <div class="m-r-35 font-20 flex align-center">
      //               <div class="huandianzhan-icon huandianzhan flex align-center ${statusBg}">
      //                 ${huandianzhan[0] == 0 ? "" : huandianzhan[0]}
      //               </div>
      //               <div class="huandianzhan-icon chongdianzhuang flex align-center ${statusBg}">
      //                 ${chongdianzhuang[0] == 0 ? "" : chongdianzhuang[0]}
      //               </div>
      //             </div>
      //           </div>`;
      //   }
      //   item.centerHtml = centerHtml;
      // });
      // this.clusterData.forEach((item, index) => {
      //   // item.name === '台湾省' || item.name === '香港特别行政区' || item.name === '澳门特别行政区'
      //   if (
      //             [710000, 820000, 810000].includes(item.cityCode)
      //   ) {
      //     console.log(item, "查询到的item");
      //     this.clusterData.splice(index, 1);
      //   }

      // });

      // let data =  this.clusterData.filter((item,index)=>{
      //   console.log(item, 'jsjsjssjsj')
      //   return item.cityCode == 710000 ||   810000 || 820000
      // } )
      let newData = [];
      // for(let i=0;i<this.clusterData.length;i++){
      //     if()
      // }
      if (this.clusterData.length == 34) {
        this.clusterData.splice(-3);
      }
      console.log(this.clusterData, "渲染的clusterDatanewData");
      setInterval(() => {
        this.opacityShow = 1;
      });
    },
  },
  filters: {},
  // 销毁定时器
  beforeDestroy: function () {},
};
</script>
  

<style>
.amap-logo {
  display: none !important;
}
.amap-copyright {
  visibility: hidden !important;
}

.amap-maptypecontrol {
  display: none !important;
}
</style>

  <style scope lang="scss">
@import "@/assets/scss/comm.scss";

// 弹窗1.0
.mapBg {
  width: 250px;
  height: 185px;
  .marsbg {
    width: 100%;
    height: 82px;
    background: url("~@/assets/images/vehicles/marsBlue.png");
    background-size: 100% 100%;
    opacity: 0.9;
    .vehicleIconImg {
      width: 20px;
      height: 20px;
      background: url("~@/assets/images/comm/vehicleIcon.jpg");
      background-size: 100% 100%;
    }
    .itemNumber {
      // margin-left: 20px;
      font-size: 48px;
      color: #fff;
      @include ffamily-DS;
    }
  }
  .marsblue {
    width: 50px;
    height: 50px;
    background: url("~@/assets/images/vehicles/blue.png");
    // background: url("~@/assets/images/vehicles/orange.png");
    background-size: 100% 100%;
    animation: Beating 1.2s ease infinite;
  }
  .marsbottom {
    width: 37px;
    height: 27px;
    background: url("~@/assets/images/vehicles/blueBottom.png");
    background-size: 100% 100%;
    animation: breath_light 3s ease infinite;
  }
}

.marsBoxBg {
  width: 400px;
  height: 60px;
  // background: red;
  background: url("~@/assets/images/index/double-bg.png");
  background-size: 100% 100%;
  opacity: 0.6;
}

.m-r-40 {
  margin-left: 25px;
}
.m-r-35 {
  margin-left: 35px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-t-20 {
  margin-top: 20px;
}
.font-15 {
  font-size: 15px;
}
.font-20 {
  font-size: 18px;
}
.marker {
  // background-image: url('mapbox-icon.png');
  background-size: cover;
  width: 165px;
  height: 45px;
  cursor: pointer;
  z-index: 1000;
}
.mapboxgl-popup {
  max-width: 200px;
}
.mapboxgl-popup-content {
  //气泡内容部分
  left: 50%;
  text-align: center;
  background: transparent;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
.mapboxgl-popup-tip {
  border: 0;
}
.mapboxgl-popup-close-button {
  display: none;
}

.pop-content {
  background: url("~@/assets/images/index/area_bg.png");
  width: 222px;
  height: 184px;
  background-size: 100% 100%;
  padding: 5px 15px;
  cursor: pointer;
  .pop-img {
    flex: 1;
    padding: 5px 0;
    img {
      width: 100%;
      border: 1px solid rgba(120, 250, 255, 0.43);
    }
  }
}
.diamand {
  margin-top: 20px;
  margin-bottom: 20px;
  // margin-left:20%;
  position: relative;
  .diamand2 {
    width: 60px;
    height: 36px;
    position: absolute;
    bottom: -30px;
    left: 36%;
  }
  .diamand1 {
    width: 46px;
    height: 52px;
    animation: bounce-down 1.5s linear infinite;
  }
}
@keyframes bounce-down {
  25% {
    transform: translateY(-10px);
  }
  50%,
  100% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(10px);
  }
}

li.bge5 {
  background-color: #e5e5e5;
}

.marsMapBgTop1 {
  width: 214px;
  height: 60px;
  background: url("~@/assets/images/index/marsMapBgTop1.png") repeat;
  background-size: 100% 100%;
  padding-top: 30px;
}
.marsMapBgBottom1 {
  width: 214px;
  height: 95px;
  background: url("~@/assets/images/index/marsMapBgBottom1.png") repeat;
  background-size: 100% 100%;
}
.marsMapBgAll {
  width: 228px;
  height: 138px;
  background: url("~@/assets/images/index/mapBg.png") repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  padding-top: 30px;
}

.marsMapBg1 {
  width: 214px;
  /* height: 128px; */
  background: url("~@/assets/images/index/mapBgCenter1.png") repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.huandianzhan-icon {
  position: relative;
  width: 50%;
  // padding-left: 30px;
  font-weight: 800;
}
.huandianzhan-icon::before {
}
.huandianzhan::before {
  content: "";
  background: url("~@/assets/images/index/huandian-icon-1.png");
  background-size: 100% 100%;
  display: block;
  position: absolute;
  width: 30px;
  height: 20px;
  margin-right: 40px;
  left: 0;
}
.yellow {
  font-weight: 800;
  color: #fedc31;
}

.huandianzhan.yellow::before {
  content: "";
  background: url("~@/assets/images/index/huandianzhanY.png");
  background-size: 100% 100%;
  display: block;
  position: absolute;
  width: 30px;
  height: 20px;
  margin-right: 40px;
  left: 0;
}

.green {
  font-weight: 800;
  color: #36fbfb;
}
.huandianzhan.green::before {
  content: "";
  background: url("~@/assets/images/index/huandianzhanG.png");
  background-size: 100% 100%;
  display: block;
  position: absolute;
  width: 30px;
  height: 20px;
  margin-right: 40px;
  left: 0;
}

.chongdianzhuang.green::before {
  content: "";
  background: url("~@/assets/images/index/chongdianzhuangG.png");
  background-size: 100% 100%;
  display: block;
  position: absolute;
  width: 25px;
  height: 24px;
  margin-right: 35px;
  left: 0;
}
.chongdianzhuang.yellow::before {
  content: "";
  background: url("~@/assets/images/index/chongdianzhuangY.png");
  background-size: 100% 100%;
  display: block;
  position: absolute;
  width: 25px;
  height: 24px;
  margin-right: 35px;
  left: 0;
}
.chongdianzhuang::before {
  content: "";
  background: url("~@/assets/images/index/chongdian-icon-1.png");
  background-size: 100% 100%;
  display: block;
  position: absolute;
  width: 25px;
  height: 24px;
  margin-right: 35px;
  left: 0;
}
</style>